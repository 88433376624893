import execute from './post';
import { IUserMilestone } from '@faire/web-api--source/indigofair/data/IUserMilestone';
import { EndpointOptions, SuccessResponse } from '@faire/web-api--source/types';
import { WebApiError } from '@faire/web-api--source/WebApiError';
import {
  FetchQueryOptions,
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryFunctionContext,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useMutation as useReactQueryMutation,
  useQuery as useReactQuery,
  useQueryClient,
  useSuspenseQuery as useSuspenseReactQuery,
} from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 * Returns a query key that should be used with useQuery_escape_hatch.
 *
 * Disclaimer: Do not use this with useInfiniteQuery_escape_hatch, prefer getInfiniteQueryKey_escape_hatch.
 */
export function getQueryKey_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions
) {
  return [`/api/user/milestones/${userToken}`, data, options];
}

/**
 * Returns a query key that should be used with useInfiniteQuery_escape_hatch.
 */
export function getInfiniteQueryKey_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions
): ['infinite', ...ReturnType<typeof getQueryKey_escape_hatch>] {
  return ['infinite', ...getQueryKey_escape_hatch(userToken, data, options)];
}

export function getQueryFn_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions
) {
  return ({ signal }: QueryFunctionContext) => {
    const optionsWithSignal = { ...options, signal };
    return execute(userToken, data, optionsWithSignal);
  };
}

export function useQuery_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  reactQueryOptions?: Omit<
    UseQueryOptions<IUserMilestone, WebApiError, IUserMilestone, QueryKey>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<IUserMilestone>;
export function useQuery_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      SuccessResponse<IUserMilestone>,
      WebApiError,
      SuccessResponse<IUserMilestone>,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions & { rawResponse: true }
): UseQueryResult<SuccessResponse<IUserMilestone>>;
export function useQuery_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  reactQueryOptions?: Omit<
    UseQueryOptions<IUserMilestone, WebApiError, IUserMilestone, QueryKey>,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseQueryResult<IUserMilestone>;
export function useQuery_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  reactQueryOptions?:
    | Omit<
        UseQueryOptions<IUserMilestone, WebApiError, IUserMilestone, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | Omit<
        UseQueryOptions<
          SuccessResponse<IUserMilestone>,
          WebApiError,
          SuccessResponse<IUserMilestone>,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >,
  options?: EndpointOptions
): UseQueryResult<IUserMilestone | SuccessResponse<IUserMilestone>> {
  return useReactQuery({
    queryKey: getQueryKey_escape_hatch(userToken, data, options),
    queryFn: getQueryFn_escape_hatch(userToken, data, options),
    ...(reactQueryOptions as Omit<
      UseQueryOptions<IUserMilestone, WebApiError, IUserMilestone, QueryKey>,
      'queryKey' | 'queryFn'
    >),
  });
}

export function useSuspenseQuery_escape_hatch(
  userToken: string,
  data: IUserMilestone,
  suspenseReactQueryOptions?: Omit<
    UseSuspenseQueryOptions<
      IUserMilestone,
      WebApiError,
      IUserMilestone,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseSuspenseQueryResult<IUserMilestone> {
  return useSuspenseReactQuery({
    queryKey: getQueryKey_escape_hatch(userToken, data, options),
    queryFn: getQueryFn_escape_hatch(userToken, data, options),
    ...(suspenseReactQueryOptions as Omit<
      UseSuspenseQueryOptions<
        IUserMilestone,
        WebApiError,
        IUserMilestone,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function usePrefetchQuery_escape_hatch() {
  const queryClient = useQueryClient();
  const prefetch = useCallback(
    (
      userToken: string,
      data: IUserMilestone,
      fetchQueryOptions?: FetchQueryOptions<
        IUserMilestone,
        WebApiError,
        IUserMilestone,
        ReturnType<typeof getQueryKey_escape_hatch>
      >,
      options?: EndpointOptions
    ) => {
      return queryClient.prefetchQuery({
        queryKey: getQueryKey_escape_hatch(userToken, data, options),
        queryFn: getQueryFn_escape_hatch(userToken, data, options),
        ...fetchQueryOptions,
      });
    },
    [queryClient]
  );
  return prefetch;
}

/**
 * Returns a function that invalidates the query for this endpoint.
 * After being invalidated the query will be refetched on the next useQuery_escape_hatch call.
 */
export function useInvalidateQuery_escape_hatch() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      userToken: string,
      data: IUserMilestone,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getQueryKey_escape_hatch(userToken, data, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}

/**
 * Returns a function that invalidates the infinite query for this endpoint.
 * After being invalidated the query will be refetched on the next useInfiniteQuery_escape_hatch call.
 */
export function useInvalidateInfiniteQuery_escape_hatch() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      userToken: string,
      data: IUserMilestone,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getInfiniteQueryKey_escape_hatch(userToken, data, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}

export function useMutation(
  reactQueryOptions?: Omit<
    UseMutationOptions<IUserMilestone, WebApiError, Parameters<typeof execute>>,
    'mutationFn'
  >
) {
  return useReactQueryMutation<
    IUserMilestone,
    WebApiError,
    Parameters<typeof execute>
  >({
    mutationFn: (params) => execute(...params),
    ...reactQueryOptions,
  });
}
