import {
  isPDP,
  isValidFaireDirectPath,
  isValidFaireDirectPathWithoutQualification,
} from "@faire/retailer-visitor-shared/hooks/FaireDirect/useFaireDirectBrand/VALID_LOGGED_OUT_FAIRE_DIRECT_ROUTES";
import { getFaireDirectBrandToken } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandToken";
import { Path } from "@faire/web--source/common/Path";
import { Location } from "history";

export const determineIsFaireDirectActiveInLoggedOut = (location: Location) => {
  const faireDirectBrandToken = getFaireDirectBrandToken() ?? "";
  const pathname = location.pathname;

  if (!isValidFaireDirectPath(pathname, faireDirectBrandToken)) {
    return false;
  }

  if (isValidFaireDirectPathWithoutQualification(pathname)) {
    return true;
  }

  /**
   * Non-Faire Direct products should not be Faire Direct active
   * so that the non-Faire Direct sign-up modal is shown.
   */
  if (isPDP(pathname) && !!faireDirectBrandToken) {
    const brandToken = new Path(location).searchParams.get("brand");

    if (brandToken && faireDirectBrandToken !== brandToken) {
      return false;
    }
  }

  return !!faireDirectBrandToken;
};
