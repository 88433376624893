import { useIsLoggedInRetailerOrBrandPreview } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { useBrandDiscounts } from "@faire/retailer-visitor-shared/stores/domain/useBrandDiscounts";
import { trackFaireDirectDiscountUndefined } from "@faire/web-api--source/events/faireDirect/actionUnknown/discountUndefined";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";

import { useFaireDirectBrand } from "../useFaireDirectBrand/useFaireDirectBrand";

import { useFaireDirectFirstOrderIncentiveAmountFields } from "./__internal__/useFaireDirectFirstOrderIncentiveAmountFields";
import { useFaireDirectFirstOrderIncentiveExpiresAtFields } from "./__internal__/useFaireDirectFirstOrderIncentiveExpiresAtFields";
import { useFaireDirectFirstOrderIncentiveHasIncentiveFields } from "./__internal__/useFaireDirectFirstOrderIncentiveHasIncentiveFields";
import { parseFaireDirectFirstOrderIncentiveFromBrandDiscounts } from "./shared/parseFaireDirectFirstOrderIncentiveFromBrandDiscounts";

export const useFaireDirectFirstOrderIncentive = () => {
  const { brandDiscounts, refetch, isPending } = useBrandDiscounts();
  const isLoggedInRetailerOrBrandPreview =
    useIsLoggedInRetailerOrBrandPreview();

  const faireDirectFirstOrderIncentive =
    parseFaireDirectFirstOrderIncentiveFromBrandDiscounts(brandDiscounts);

  const { brand_token: brandToken, brand_name: brandName } =
    faireDirectFirstOrderIncentive ?? {};

  const hasIncentiveFields =
    useFaireDirectFirstOrderIncentiveHasIncentiveFields(
      faireDirectFirstOrderIncentive
    );

  const expiresAtFields = useFaireDirectFirstOrderIncentiveExpiresAtFields(
    faireDirectFirstOrderIncentive
  );

  const incentiveAmountFields = useFaireDirectFirstOrderIncentiveAmountFields(
    faireDirectFirstOrderIncentive
  );

  const refetchFaireDirectFirstOrderIncentive = refetch;

  const { isFaireDirectActiveInLoggedOut, faireDirectBrandToken } =
    useFaireDirectBrand();

  const isFaireDirectFirstOrderIncentiveUndefined =
    !isPending && isEmpty(faireDirectFirstOrderIncentive);

  useEffect(() => {
    // FD FOI should always be defined on logged-out views from valid Faire Direct page
    if (
      !isLoggedInRetailerOrBrandPreview &&
      isFaireDirectActiveInLoggedOut &&
      isFaireDirectFirstOrderIncentiveUndefined
    ) {
      trackFaireDirectDiscountUndefined(faireDirectBrandToken);
    }
  }, [
    isFaireDirectFirstOrderIncentiveUndefined,
    isFaireDirectActiveInLoggedOut,
    faireDirectBrandToken,
  ]);

  return {
    brandToken,
    brandName,
    ...hasIncentiveFields,
    ...expiresAtFields,
    ...incentiveAmountFields,
    refetchFaireDirectFirstOrderIncentive,
  };
};
