import { request } from '@faire/web-api--source';
import { IBrand } from '@faire/web-api--source/indigofair/data/IBrand';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';
import qs from 'query-string';

export type QueryParameters = {
  load_upcoming_market_promo: boolean | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (brandToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/brand/${brandToken}${queryString}`;
};

export const build = (
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(brandToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/brand/:brand_token';

/**
 * The path param takes both brand token and brand alias
 * Product Area: BRAND_CORE
 */
function execute(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<IBrand>;
function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IBrand>>;
function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IBrand>;
function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IBrand | SuccessResponse<IBrand>> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<IBrand>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/brand/brandToken/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<IBrand>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IBrand>>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IBrand>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IBrand | SuccessResponse<IBrand>> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<IBrand>(requestOptions);
}
