import { IFaireMoney } from '@faire/web-api--source/indigofair/data/IFaireMoney';
import { SettingConst } from '@faire/web-api--source/types';

/**
 * Amount of Faire Direct credit to give to new Faire Direct retailers
 */
export const FAIRE_DIRECT_RETAILER_AWARD_AMOUNT =
  'FAIRE_DIRECT_RETAILER_AWARD_AMOUNT' as SettingConst<
    'FAIRE_DIRECT_RETAILER_AWARD_AMOUNT',
    IFaireMoney,
    'RETAILER' | 'MAKER' | 'BRAND_WIDGET' | 'VISITOR_WEB'
  >;
