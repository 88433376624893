import { IAddress } from "@faire/web-api--source/indigofair/data/IAddress";
import isURL from "validator/lib/isURL";

export const isValidPublicUrl = (url: string): boolean =>
  isURL(url, { disallow_auth: true });

export const isValidEmail = (email: string | undefined): boolean => {
  if (!email) {
    return false;
  }
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};

export const validateAddress = (
  requiredFields: string[],
  address?: Partial<IAddress>
) => {
  const errors: Record<string, string> = {};
  if (!address) {
    return errors;
  }
  if (
    address.phone_number &&
    address.phone_number.length > 0 &&
    address.phone_number.charAt(0) !== "+" &&
    address.phone_number.length !== 10
  ) {
    errors.phone_number = "Phone number must be 10 digits";
  }

  requiredFields.forEach((fieldKey) => {
    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    if (!address[fieldKey] || address[fieldKey].trim().length === 0) {
      errors[fieldKey] = "Field cannot be blank";
    }
  });

  return errors;
};
