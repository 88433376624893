import { getBrandDiscounts } from "@faire/retailer-visitor-shared/serialized-data/getBrandDiscounts";
import { useQuery as useCartBrandDiscountsQuery } from "@faire/web-api--source/endpoints/www/api/cart/brand-discounts/get-hooks";
import { useMemo } from "react";

export const useBrandDiscounts = () => {
  const { data, refetch, isPending } = useCartBrandDiscountsQuery({
    initialData: getBrandDiscounts,
    staleTime: Infinity,
  });

  return useMemo(() => {
    const brandDiscounts = data?.brand_discounts ?? {};

    return { brandDiscounts, refetch, isPending };
  }, [data?.brand_discounts, refetch, isPending]);
};
