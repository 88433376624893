import { IUserMilestone } from '@faire/web-api--source/indigofair/data/IUserMilestone';

export namespace IGetUserMilestonesResponse {
  /**
   * Return an instance of IGetUserMilestonesResponse with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IGetUserMilestonesResponse>
  ): IGetUserMilestonesResponse =>
    ({
      milestones: {},
      ...(partial ?? {}),
    }) as IGetUserMilestonesResponse;
}

export interface IGetUserMilestonesResponse {
  milestones: Partial<Record<string, IUserMilestone>>;
}
