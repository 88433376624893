import {
  AllowedFormats,
  intlDate,
} from "@faire/web--source/common/localization/date-fns/intlDate";
import { IBrandDiscount } from "@faire/web-api--source/indigofair/data/IBrandDiscount";
import { differenceInDays } from "date-fns/differenceInDays";
import { isAfter } from "date-fns/isAfter";
import { isWithinInterval } from "date-fns/isWithinInterval";
import { startOfDay } from "date-fns/startOfDay";
import { subDays } from "date-fns/subDays";
import { useCallback } from "react";

const FORMATTED_EXPIRES_AT_DEFAULT = "MMMM d";

export const useFaireDirectFirstOrderIncentiveExpiresAtFields = (
  faireDirectFirstOrderIncentive: IBrandDiscount | undefined
) => {
  const expiresAt = faireDirectFirstOrderIncentive?.expires_at;

  const getFormattedExpiresAt = useCallback(
    (format: AllowedFormats): string => {
      if (!expiresAt) {
        return "";
      }

      return intlDate(expiresAt, format ?? FORMATTED_EXPIRES_AT_DEFAULT);
    },
    [expiresAt]
  );

  const remainingDaysBeforeExpiry = getRemainingDaysBeforeExpiry(expiresAt);

  const isLastDayBeforeExpiry = getIsLastDayBeforeExpiry(expiresAt);

  const isSecondLastDayBeforeExpiry = getIsSecondLastDayBeforeExpiry(expiresAt);

  return {
    expiresAt,
    getFormattedExpiresAt,
    remainingDaysBeforeExpiry,
    isLastDayBeforeExpiry,
    isSecondLastDayBeforeExpiry,
  };
};

const getRemainingDaysBeforeExpiry = (expiresAt: number | undefined) => {
  if (!expiresAt || isAfter(Date.now(), expiresAt)) {
    return 0;
  }

  return differenceInDays(startOfDay(expiresAt), startOfDay(Date.now()));
};

const getIsLastDayBeforeExpiry = (expiresAt: number | undefined) => {
  if (!expiresAt) {
    return false;
  }

  const oneDayBeforeExpiresAt = subDays(expiresAt, 1);

  return isWithinInterval(Date.now(), {
    start: oneDayBeforeExpiresAt,
    end: expiresAt,
  });
};

const getIsSecondLastDayBeforeExpiry = (expiresAt: number | undefined) => {
  if (!expiresAt) {
    return false;
  }

  const oneDayBeforeExpiresAt = subDays(expiresAt, 1);
  const twoDaysBeforeExpiresAt = subDays(expiresAt, 2);

  return isWithinInterval(Date.now(), {
    start: twoDaysBeforeExpiresAt,
    end: oneDayBeforeExpiresAt,
  });
};
