import { trackClientEvent } from '@faire/web-api--source/trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'faire_direct-discount_undefined-action_unknown';

/**
 * Fires when the faire direct discount is undefined.
 */
export function trackFaireDirectDiscountUndefined(
  brandToken: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
    parameters: { brandToken },
  });
}
