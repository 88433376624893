import { request } from '@faire/web-api--source';
import { IUserMilestone } from '@faire/web-api--source/indigofair/data/IUserMilestone';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = (userToken: string) => {
  return `/api/user/milestones/${userToken}`;
};

export const build = (
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(userToken),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/user/milestones/:user_token';

/**
 * Product Area: MILESTONES
 */
function execute(
  userToken: string,
  data: IUserMilestone
): Promise<IUserMilestone>;
function execute(
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IUserMilestone>>;
function execute(
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions
): Promise<IUserMilestone>;
function execute(
  userToken: string,
  data: IUserMilestone,
  options?: EndpointOptions
): Promise<IUserMilestone | SuccessResponse<IUserMilestone>> {
  const { route, ...requestOptions } = build(userToken, data, options);
  return request<IUserMilestone>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/user/milestones/userToken/post';
export default execute;
