import { determineIsFaireDirectActiveInLoggedOut } from "@faire/retailer-visitor-shared/hooks/FaireDirect/useFaireDirectBrand/determineIsFaireDirectActiveInLoggedOut";
import { getBrand } from "@faire/retailer-visitor-shared/serialized-data/getBrand";
import { getFaireDirectBrandAlias } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandAlias";
import { getFaireDirectBrandToken } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandToken";
import { useBrandStore } from "@faire/retailer-visitor-shared/stores/domain/BrandStore";
import { useQuery as useBrandQuery } from "@faire/web-api--source/endpoints/www/api/brand/brandToken/get-hooks";
import { useLocation } from "react-router-dom";

export const useFaireDirectBrand = () => {
  const faireDirectBrandToken = getFaireDirectBrandToken() ?? "";
  /**
   * Checking whether Faire Direct is 'active' is derived from being on `/direct` (as a logged-out user)
   * or having the `fdb` query parameter appended. This check is only reliable and meaningful in the logged-out
   * state because in logged-in Faire Direct presence is determined through the relevant active incentive.
   */
  const location = useLocation();

  const isFaireDirectActiveInLoggedOut =
    determineIsFaireDirectActiveInLoggedOut(location);

  const { prefetchedFullBrand } = useBrandStore(["prefetchedFullBrand"]);
  const { data: faireDirectBrand } = useBrandQuery(
    faireDirectBrandToken,
    undefined,
    {
      enabled: isFaireDirectActiveInLoggedOut,
      initialData: () => {
        if (getBrand()?.token === faireDirectBrandToken) {
          return getBrand();
        }

        if (prefetchedFullBrand?.token === faireDirectBrandToken) {
          return prefetchedFullBrand;
        }

        return undefined;
      },
      staleTime: 1000 * 60 * 5,
    }
  );

  const faireDirectBrandAlias =
    faireDirectBrand?.token_alias ?? getFaireDirectBrandAlias() ?? "";

  return {
    faireDirectBrandToken,
    isFaireDirectActiveInLoggedOut,
    faireDirectBrand,
    faireDirectBrandAlias,
  };
};
