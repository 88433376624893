import { MilestoneType } from '@faire/web-api--source/indigofair/data/MilestoneType';

export namespace IUserMilestone {
  export namespace RetailerMilestone {
    export const isRetailerMilestone = Symbol(
      'indigofair.data.IUserMilestone.RetailerMilestone'
    );
  }

  /**
   * @protobufOption (faire.protobuf.webApiEnumOutput) = SHARDED_CONSTS
   */
  export type RetailerMilestone = string & {
    [RetailerMilestone.isRetailerMilestone]: true;
  };

  export type RetailerMilestoneExtensions = {
    deprecated?: boolean;
  };

  export namespace BrandMilestone {
    export const isBrandMilestone = Symbol(
      'indigofair.data.IUserMilestone.BrandMilestone'
    );
  }

  /**
   * @protobufOption (faire.protobuf.webApiEnumOutput) = SHARDED_CONSTS
   */
  export type BrandMilestone = string & {
    [BrandMilestone.isBrandMilestone]: true;
  };

  export type BrandMilestoneExtensions = {
    deprecated?: boolean;
    milestoneType?: keyof typeof MilestoneType;
  };

  /**
   * Return an instance of IUserMilestone with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IUserMilestone>): IUserMilestone =>
    ({
      ...(partial ?? {}),
    }) as IUserMilestone;
}

export interface IUserMilestone {
  /**
   * A milestone that has not been set will be shown as having count=0, createdAt=null
   */
  count: number | undefined;
  updated_at: number | undefined;
  created_at: number | undefined;
  retailer_milestone: IUserMilestone.RetailerMilestone | undefined;
  brand_milestone: IUserMilestone.BrandMilestone | undefined;
  /**
   * This named milestone allows old mobile clients to set new milestones
   * using the SDUI framework. The apps will get the milestone name from the
   * RecordMilestoneAction proto. The server will convert that to the appropriate
   * enum.
   */
  retailer_milestone_name: string | undefined;
  /**
   * This named milestone allows old mobile clients to set new milestones using the BE-Driven Messaging Components
   * framework. The apps will get the milestone name from the UiMessagingContainer proto. The server will convert that
   * to the appropriate enum.
   */
  brand_milestone_name: string | undefined;
}
